import React, { useState } from 'react';
import styled from "styled-components";
import { useSpring, animated, config } from "react-spring";
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import useDocumentScrollThrottled from "./useDocumentScrollThrottled"
import { FaFacebookF, FaTwitter } from "react-icons/fa";


import BurgerMenu from "./burger-menu";
import CollapseMenu from "./collapse-menu";



const Navbar = (props) => {
//   const barAnimation = useSpring({
//     from: { transform: 'translate3d(0, -10rem, 0)' },
//     transform: 'translate3d(0, 0, 0)',
//   });

  // const linkAnimation = useSpring({
  //   from: { transform: 'translate3d(0, 10px, 0)', opacity: 0 },
  //   to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
  //   delay: 800,
  //   config: config.wobbly,
  // });

  const data = useStaticQuery(graphql`
  query MyQuery {
    file(relativePath: {eq: "ni-conservatives-logo-light-blue.png"}) {
      id
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    logo: contentfulLogos {
      mainLogo {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
  `)

  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [shouldShowShadow, setShouldShowShadow] = useState(false);

  const MINIMUM_SCROLL = 50;
  const TIMEOUT_DELAY = 100;

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setShouldShowShadow(currentScrollTop > 2);

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const isOpen = props.navbarState

  const shadowStyle = shouldShowShadow && !isOpen ? 'shadow' : '';
  const hiddenStyle = shouldHideHeader && !isOpen ? 'hidden' : '';

  return (
    <>
      <NavBar className={`header ${shadowStyle} ${hiddenStyle}`}>
        <FlexContainer>
          <Link to="/" style={{width: 200}}>
            <div className="logo-box">   
                <Img style={{width: 200}} objectFit="contain" fluid={data.logo.mainLogo.fluid} />
            </div> 
          </Link>
          <NavLinks >
            <div>
            <NavLinkButton activeStyle={{ textDecorationColor: "#FFFFFF", cursor: "default" }} to="/values">About</NavLinkButton>
            <NavLinkButton activeStyle={{ textDecorationColor: "#FFFFFF", cursor: "default" }} to="/people">People</NavLinkButton>
            <NavLinkButton activeStyle={{ textDecorationColor: "#FFFFFF", cursor: "default" }} to="/articles">Articles</NavLinkButton>
            <NavLinkButton activeStyle={{ textDecorationColor: "#FFFFFF", cursor: "default" }} to="/contact">Contact</NavLinkButton>
            </div>
          </NavLinks>
          <BurgerWrapper>
            <BurgerMenu
              navbarState={props.navbarState} 
              handleNavbar={props.handleNavbar}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu 
        navbarState={props.navbarState} 
        handleNavbar={props.handleNavbar}
      />
    </>
  )
}

export default Navbar

const NavBar = styled(animated.nav)`
  position: fixed;
  z-index: 30 !important;
  width: 100%;
  top: 0;
  left: 0;
  background: #004225;
  z-index: 1;
  font-family: 'Raleway', sans-serif !important;
  font-weight: 700;
  font-size: 1.5rem;
  padding-top: 15px;
  padding-bottom: 15px;

  @media (max-width: 769px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const FlexContainer = styled.div`
  max-width: 1200px;
  display: flex;
  margin: auto;
  padding: 0px 40px;
  justify-content: space-between;
  height: 5rem;
  aligh-items: center !important;

  .logo-box{
    height: 100%;
    display: flex;
    justify-content: center !important;
  }

  @media (max-width: 769px) {
    padding: 0px 2rem;
  }
`;

const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
  
  
`;

const NavLinkButton = styled(Link)`
    color: #FFFFFF;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    margin: 0 .8rem;
    transition: all 300ms linear 0s;
    text-decoration: underline;
    text-decoration-color:#004225;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    &:hover {
    color: #FFFFFF;
    text-decoration-color:#FFFFFF;
    transition: all 300ms linear 0s;


    }
    @media (max-width: 768px) {
    display: none;
    }
`

const SocialButton = styled(NavLinkButton)`
    background-color: #f2f2f2;
    color: #707070;
    &:hover {
        background-color: #00aeef;
        color: #FFFFFF;
        transition: all 300ms linear 0s;
    }
`

const DonateButton = styled(NavLinkButton)`
    background-color: #e21e26;
    color: #ffffff;
    font-size: 18px;
    padding: 9px 20px;
    margin-right: 0px;
    &:hover{
      color: #ffffff;
      background-color: #c51920;
      transition: all 300ms linear 0s;
    }
`
 
const BurgerWrapper = styled.div`
  margin: auto 0;
  @media (min-width: 769px) {
    display: none;
  }
`;