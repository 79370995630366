import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"
import { FaFacebookF, FaTwitter } from "react-icons/fa";


import { useSpring, animated } from 'react-spring';



const CollapseMenu = (props) => {
  const { open } = useSpring({ open: props.navbarState ? 0 : 1 });

  if (props.navbarState === true) {
    return (
      
      <CollapseWrapper style={{
        transform: open.interpolate({
          range: [0, 0.2, 0.3, 1],
          output: [0, -20, 0, -350],
        }).interpolate(openValue => `translate3d(0, ${openValue}px, 0`),
      }} 
      >
        <NavLinks>
          <li><NavLinkButton activeStyle={{ textDecoration: "underline" }} to="/values" onClick={props.handleNavbar}>About</NavLinkButton></li>
          <li><NavLinkButton activeStyle={{ textDecoration: "underline" }} to="/people" onClick={props.handleNavbar}>People</NavLinkButton></li>
          <li><NavLinkButton activeStyle={{ textDecoration: "underline" }} to="/articles" onClick={props.handleNavbar}>Articles</NavLinkButton></li>
          <li><NavLinkButton activeStyle={{ textDecoration: "underline" }} to="/contact" onClick={props.handleNavbar}>Contact</NavLinkButton></li>
          <SocialList style={{ display: "flex", listStyle: "none", fontSize: "2rem", marginLeft: "0", paddingLeft: "0", paddingTop: "20px" }}>
            <SocialButtons><a className="social-links" style={{ color: "#0a3b7c" }} href="https://www.facebook.com/FrancisHutchesonInstitute"><FaFacebookF /></a></SocialButtons>
            <SocialButtons ><a className="social-links" style={{ marginLeft: "20px" }} href="https://twitter.com/HutchesonInst"><FaTwitter /></a></SocialButtons>
        </SocialList>
          {/* <li><DonateButton href="https://donate.conservatives.com">Donate</DonateButton></li> */}
        </NavLinks>
      </CollapseWrapper>
      
    );
  }
  return (
    
    <CollapseWrapper style={{
      transform: open.interpolate({
        range: [0, 0.2, 0.3, 1],
        output: [0, -20, 0, -350],
      }).interpolate(openValue => `translate3d(0, ${openValue}px, 0`),
    }} 
    >
      <NavLinks>
        <li><NavLinkButton activeStyle={{ textDecoration: "underline" }} to="/values" onClick={props.handleNavbar}>About</NavLinkButton></li>
        <li><NavLinkButton activeStyle={{ textDecoration: "underline" }} to="/people" onClick={props.handleNavbar}>People</NavLinkButton></li>
        <li><NavLinkButton activeStyle={{ textDecoration: "underline" }} to="/articles" onClick={props.handleNavbar}>Articles</NavLinkButton></li>
        <li><NavLinkButton activeStyle={{ textDecoration: "underline" }} to="/contact" onClick={props.handleNavbar}>Contact</NavLinkButton></li>
        <SocialList style={{ display: "flex", listStyle: "none", fontSize: "2rem", marginLeft: "0", paddingLeft: "0", paddingTop: "20px" }}>
            <SocialButtons><a className="social-links" style={{ color: "#0a3b7c" }} href="https://www.facebook.com/FrancisHutchesonInstitute"><FaFacebookF /></a></SocialButtons>
            <SocialButtons ><a className="social-links" style={{ marginLeft: "20px" }} href="https://twitter.com/HutchesonInst"><FaTwitter /></a></SocialButtons>
        </SocialList>
        {/* <li><DonateButton href="https://donate.conservatives.com">Donate</DonateButton></li> */}
      </NavLinks>
    </CollapseWrapper>
    
  );
};

export default CollapseMenu;

const CollapseWrapper = styled(animated.div)`
  background: #004225;
  width: 100vw;
  position: fixed;
  top: 4.5rem;
  left: 0;
  right: 0;
  font-family: 'Raleway', sans-serif !important;
  font-weight: 700;
  padding-top: 20px;
  box-shadow: 0 9px 9px -9px rgba(0, 0, 0, 0.13);
  @media (max-width: 768px) {
        z-index: 20;
    }
`;

const NavLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 0rem 1rem;
  margin-bottom: 15px !important;
  margin-right: 1.45rem;

  .social-links {
    padding-top: .7rem;
    padding-left: .7rem;
    padding-right: .7rem;
    padding-bottom: .1rem;
    background-color: #FFFFFF;
    color: #004225 !important;
    border-radius: 4px;
  }
  
`;

const NavLinkButton = styled(Link)`
    font-size: 1.4rem;
    line-height: 2;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    
`

const DonateButton = styled(Link)`
  display: block;
  background-color: #e21e26;
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 0;
  text-decoration: none;
`

const SocialList = styled.ul`

`

const SocialButtons = styled.li`

`
