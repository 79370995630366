import React from 'react';
import styled from 'styled-components'
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery, Link, navigate } from "gatsby"
import { FaFacebookF, FaTwitter } from "react-icons/fa";


const Footer = () => {

    const data = useStaticQuery(graphql`
        query{
           
            logo:contentfulLogos {
                emblem {
                  fluid(maxWidth: 500){
                    ...GatsbyContentfulFluid_tracedSVG
                    }
                }
            }
        }
    `)

    return ( 
        <Container>
          <Wrapper>
            <TopMenu>
                <SiteLinks>
                    <Link to="/"><BottomLogo fluid={data.logo.emblem.fluid} objectFit="contain"/></Link>
                    <TopLink to="/values">About</TopLink>
                    <TopLink to="/people">People</TopLink>
                    <TopLink to="/articles">Articles</TopLink>
                    <TopLink to="/contact">Contact</TopLink>


                </SiteLinks>
                <SiteLinks className="social-links">
                    <SocialButton href="https://www.facebook.com/FrancisHutchesonInstitute"><FaFacebookF /></SocialButton>
                    <SocialButton href="https://twitter.com/HutchesonInst"><FaTwitter /></SocialButton>
                </SiteLinks>
            </TopMenu> 
            {/* <ExternalLinks>
                <LowerLink href="http://www.eoni.org.uk/Register-To-Vote/Register-to-vote-change-address-change-name">Register to Vote</LowerLink>
                <LowerLink href="https://www.conservatives.com/business">Conservatives for Business</LowerLink>
                <LowerLink href="https://youth.conservatives.com/">Young Conservatives</LowerLink>
                <LowerLink href="https://shop.conservatives.com/">Shop</LowerLink>
                <LowerLink href="https://www.conservatives.com/organisation">Party Organisation</LowerLink>
                <LowerLink href="https://membership.conservatives.com/Membership/Renew#!/">Renew your Membership</LowerLink>
                <LowerLink href="https://lottery.conservatives.com/">Lottery</LowerLink>
            </ExternalLinks> */}
            <p className="date" style={{marginTop: "20px"}}>© {new Date().getFullYear()}, Francis Hutcheson Institute</p>
            <ExternalLinks style={{marginBottom: 0}}>
                <LowerLink className="policy" to="/privacy">Privacy Policy</LowerLink>
            </ExternalLinks>

          </Wrapper>   
          
         
          
        </Container>
     );
}
 
export default Footer;

const Container = styled.footer`
    display: flex;
    justify-content: center;
    background-color: #004225;
    color: white;
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 18px;
    font-family: 'Raleway', sans-serif !important;
    font-weight: 600;
    .date{
        font-size: 22px;
        font-family: 'Cormorant Garamond', serif !important;
        line-height: 16px;
        font-weight: 500;
        font-style: italic;
        margin-bottom: 0;
        color: #FFFFFF;

        @media (max-width: 450px) {
            text-align: center;
        }
    }
    
`
const Wrapper = styled.div`
    max-width: 1200px !important;
    padding: 0 40px;
    width: 100%;
    margin: auto;
`

const TopMenu = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start;

    @media (max-width: 450px) {
        flex-direction: column;
        justify-content: center;

        .social-links{
            flex-direction: row;
            justify-items: center;
            align-items: center;
            max-width: 100px;
            margin: 20px auto;
            
        }
    }

`
const SiteLinks = styled.div`
    display: flex;
    justify-content: space-around !important;
    align-items: center;
    justify-self: flex-start !important;

    @media (max-width: 450px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

`

const TopLink = styled(Link)`
    display: block;
    margin: 0 20px;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: #004225;
    color: #ffffff;
    &:hover {
        color: #FFFFFF;
        text-decoration-color: #FFFFFF;
        transition: all 300ms linear 0s;
    }

    @media (max-width: 450px) {
        padding: 5px 0;
    }

`

const SocialButton = styled(Link)`
    display: block;
    text-decoration: none;
    color: #004225;
    background-color: #ffffff;
    margin: 0 10px;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;

    &:hover {
        color: #004225;
        background-color: #C4C4C4;
        transition: all 300ms linear 0s;
    }

`

const BottomLogo = styled(Img)`
    width: 40px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    cursor: pointer;

    @media (max-width: 450px) {
        width: 50px;
        margin-bottom: 20px;
    }
`

const ExternalLinks = styled.div`
    margin: 20px 0;
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    font-family: 'Raleway', sans-serif !important;


    @media (max-width: 450px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
    }

`

const LowerLink = styled(TopLink)`
    margin: 0;
    margin-right: 10px;
    padding: 3px 3px 3px 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    font-family: 'Raleway', sans-serif !important;
    font-size: 16px;


    text-decoration: none !important;
    

    &:hover {
        color: #ffffff;
        border-bottom: 2px solid #ffffff;
        transition: all 300ms linear 0s;
        text-decoration: none !important;

    }

    @media (max-width: 450px) {
        margin-right: 0;
    }


`