import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  
  *,
  *::after,
  *::before {
    margin: 0px;
    padding: 0px;
    box-sizing: inherit;
  }
  html {
    font-size: 62.5%;
    min-height: 100vh !important;
  }
  body {
    height: inherit !important;  
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    min-height: 100vh !important;
  }

  #gatsby-focus-wrapper {
      min-height: 100vh !important;
  }
`;

export default GlobalStyles;